<template>
  <div class="d-page-wrapper d-error-page">
    <div
      v-html="$t('notFoundPage.title')"
      class="d-error-page-title"
    ></div>
    <div
      v-html="$t('notFoundPage.message')"
      class="d-error-page-message"
    ></div>
    <router-link
      :to="{ name: 'home' }"
      v-html="$t('notFoundPage.backHome')"
      class="mt-6 d-block"
    ></router-link>
  </div>
</template>


<script>
import pageMixin from '@/mixins/page.mixin'


export default {
  mixins: [
    pageMixin
  ],


  async created () {
    this.setPageTitle('404')
  }
}
</script>
